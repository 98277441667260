import { OnboardingFlows } from '../constants/onboarding';

/**
 * Retrieve the Onboarding flow based on the entryUrl
 * @param {string} entryUrl - The entry URL to find the flow for
 * @returns {object | null} - Returns the matching flow or null if not found
 */
export const getOnboardingFlowByEntryUrl = (entryUrl) => {
  return (
    OnboardingFlows.find((flow) => {
      const dynamicUrlPattern = flow.entryUrl.replace(
        /:([^/]+)/g,
        '([^/]+)/?'
      ); // takes care of /:partnerName
    
      const regex = new RegExp(`^${dynamicUrlPattern}$`);
      return regex.test(entryUrl);
    }) || null
  );
};


/**
 * Retrieve the Onboarding flow based on the key
 * @param {string} key - The key to find the flow for
 * @returns {object | null} - Returns the matching flow or null if not found
 */
export const getOnboardingFlowByKey = (key) => {
  return OnboardingFlows.find((flow) => flow.key === key) || {};
};

/**
 * Retrieve the Onboarding flow array routes  based on the key
 * @param {string} key - The key to find the flow for
 * @returns {object | null} - Returns the matching flow steps or null if not found
 */
export const getOnboardingRoutes = (key) => {
  return OnboardingFlows.find((flow) => flow.key === key)?.steps || [];
};

/**
 * Retrieve the Onboarding flow steps array length 
 * @param {string} key - The key to find the flow for
 * @returns {object | null} - Returns the matching flow or null if not found
 */
export const getOnboardingTotalSteps = (key) => {
  return OnboardingFlows.find((flow) => flow.key === key)?.steps?.length || 0;
};

export const getRouteList = (key) => {
  const flow = OnboardingFlows.find((f) => f.key === key);
  if (!flow) {
    return null;
  }
  const sortedRoutes = flow.steps.flatMap((step) => step.routes);
  return sortedRoutes;
};

// Main function to get the next step
export const getNextStep = (key, location) => {
  const routeList = getRouteList(key);
  if (!routeList) {
    console.log(`Onboarding flow with key "${key}" not found.`);
    return null;
  }

  const currentIndex = routeList.indexOf(location);
  if (currentIndex === -1) {
    console.log(
      `Location "${location}" not found in onboarding flow "${key}".`
    );
    return null;
  }

  // Return the next route or null if it's the last step
  return routeList[currentIndex + 1] || null;
};
