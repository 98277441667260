import React, { useRef, useState } from 'react';
import styled from 'styled-components';

import { useChain } from 'react-spring';
import { connect, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Heading from '../atoms/Heading';
import AnimatedTitle from '../atoms/AnimatedTitle';
import AnimatedContent from '../atoms/AnimatedContent';
import Content from '../atoms/Content';
import { palette } from '../theme/palette';
import OshiHeader from './OshiHeader';
import SignedInAs from './SignedInAs';
import BackgroundBanner from '../atoms/RightSidedBanner';
import { localStorageSave } from '../utils/localStorageHelper';
import useWindowSize from '../hooks/useWindowSize';
import {
  trackSignOutBtnPressed,
  userBackButtonEvent,
} from '../actions/segment';
import { CURRENT_PARTNERS } from '../constants/subscriptionStatus';
import { ROUTES } from '../constants/routes';
import PartnersBanner from '../atoms/PartnersBanner';
import { SignInLink } from '../atoms/SignInLink';

function AnimatedRoute(props) {
  const {
    title = '',
    titleStyle = '',
    preHeader = '',
    banner = null,
    children,
    nextRoute,
    showProgressBar = true,
    animationDirection,
    trackBackButtonSegmentEventFn = null,
    userBackButtonEvent: trackBackBtnEvent,
    trackSignOutBtnPressed: trackSignOutBtnEvent,
    setAnimationDirection,
    isRightLayout = false,
    onSignInClick = null,
    nextRouteProps = {}, // these are props you can pass on to the next route
    titleContainerStyle,
    animatedContentStyles = {},
    hideHeader = false,
  } = props;
  const titleAnimeRef = useRef();
  const contentAnimeRef = useRef();
  const history = useHistory();
  const [resetAnimaions] = useState(false);
  const [
    cameAfterOshiHeaderBackButtonClick,
    setCameAfterOshiHeaderBackButtonClick,
  ] = useState(false);
  const cameAfterBrowserBackButtonClick = history.action === 'POP';
  const pathList = history.location.pathname.split('/');
  const route = pathList[1];
  const routePathParam = pathList[2];
  const hasNoHeaderPage =
    route === ROUTES.NEW_PARTNER.slice(1) ||
    route === ROUTES.OFF_BOARDING.slice(1) ||
    hideHeader;
  const isMarpaiRoute = routePathParam === CURRENT_PARTNERS.MARPAI;

  const { screenSize } = useWindowSize();

  const { key: onboardingFlowKey } = useSelector(
      (store) => store.uiReducer?.onboardingFlow
  );

  function handleAnimationFinish(animation) {
    if (cameAfterOshiHeaderBackButtonClick) {
      history.goBack();
    } else if (animation.opacity === 0) {
      // this only gets hit when a user leaves the route and
      // submitted the needed info. So we store the next route
      // to keep track of where the user should be redirected to
      if (nextRoute) {
        localStorageSave('currentRoute', nextRoute);
        history.push(nextRoute, nextRouteProps);
      }
    }
  }

  const handleBackArrow = () => {
    setCameAfterOshiHeaderBackButtonClick(true);
    setAnimationDirection && setAnimationDirection('unmount');
  };

  const isWideScreenLayout = React.useCallback(() => {
    if (screenSize.width > 768) {
      return true;
    }
    return false;
  }, [screenSize]);

  function getHeader() {
    // some routes won't have the back arrow when
    // we don't want the user to go back
    if (hideHeader) {
      return null;
    }
    // eslint-disable-next-line default-case
    switch (route) {
      case 'name': {
        return (
          <OshiHeader
            logoType={isMarpaiRoute ? routePathParam : null}
            action={handleBackArrow}
            showProgressBar={showProgressBar}
          />
        );
      }
      case 'name-step': {
        return (
          <OshiHeader
            showProgressBar={showProgressBar}
            segment={() =>
              trackBackButtonSegmentEventFn
                ? trackBackButtonSegmentEventFn()
                : trackBackBtnEvent(`/${route}`)
            }
          />
        );
      }
      case 'first-appointment-confirm':
      case 'first-appointment-overview':
      case 'insurance-information':
        return (
          <OshiHeader
            action={handleBackArrow}
            showProgressBar={showProgressBar}
          />
        );
      case 'network-error':
      case 'new-partner':
      case 'offboarding':
        return null;
      default:
        // default case;
        return (
          <OshiHeader
            action={handleBackArrow}
            showProgressBar={showProgressBar}
            segment={() =>
              trackBackButtonSegmentEventFn
                ? trackBackButtonSegmentEventFn()
                : trackBackBtnEvent(`/${route}`)
            }
            type='back'
          />
        );
    }
  }

  const getSignOutLinkProps = React.useCallback(() => {
    const defaultProps = {
      signOutEvent: () => trackSignOutBtnEvent(`/${route}`),
    };
    if (route === 'first-appointment-book') {
      defaultProps.additionalText = `It's easy to cancel or reschedule in the Oshi Health mobile app.`;
      defaultProps.styles = { flex: '0' };
    }
    return defaultProps;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [route]);

  const getBottomContainer = React.useCallback(() => {
    switch (route) {
      case 'first-appointment-book':
      case 'first-appointment-confirm':
      case 'first-appointment-overview':
      case 'insurance-information':
      case 'insurance-card-image-upload':
      case 'policy-holder':
      case 'policy-holder-relationship': {
        const props = getSignOutLinkProps();
        return (
          <BottomContainer>
            <SignedInAs {...props} />
          </BottomContainer>
        );
      }
      default:
        return null;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [route]);

  const calculateTopPadding = React.useCallback(() => {
    if (showProgressBar) {
      // header height (58px) + progress bar height (6px) + bottom margin (16px) + top margin (32px)
      return 112;
    }
    if (hasNoHeaderPage) {
      return 0;
    }
    // header height (58px) + top margin (32px)
    return 90;
  }, [showProgressBar, hasNoHeaderPage]);

  useChain([titleAnimeRef, contentAnimeRef], [0, 0.5]);

  function getInnerContent() {
    return (
      <>
        <Heading
          animationRef={titleAnimeRef}
          resetAnimaions={resetAnimaions}
          animationDirection={animationDirection}
          cameAfterBrowserBackButtonClick={cameAfterBrowserBackButtonClick}
          cameAfterOshiHeaderBackButtonClick={
            cameAfterOshiHeaderBackButtonClick
          }
          title={title}
        ></Heading>
        <Content
          style={{
            flexDirection: 'column',
          }}
          cameAfterBrowserBackButtonClick={cameAfterBrowserBackButtonClick}
          animationRef={contentAnimeRef}
          resetAnimaions={resetAnimaions}
          animationDirection={animationDirection}
          handleAnimationFinish={handleAnimationFinish}
        >
          {children}
          {getBottomContainer()}
        </Content>
      </>
    );
  }

  function getRightLayout() {
    const isWideScreen = isWideScreenLayout();

    return (
      <LayoutContainer>
        <BackgroundBanner isRightLayout={true} maxWidth={'542px'} />
        <RightContainer>
          {banner && <BannerContainer>{banner}</BannerContainer>}
          <FlexContent>
            {isWideScreen ? (
              <ContentWrapper>{getInnerContent()}</ContentWrapper>
            ) : (
              getInnerContent()
            )}
          </FlexContent>
          <FooterContainer>
            {onboardingFlowKey !== 'onboarding-2-partnerName' && (
              <PartnersBanner />
            )}
            <SignInLink onSignInClick={onSignInClick} />
          </FooterContainer>
        </RightContainer>
      </LayoutContainer>
    );
  }

  return isRightLayout ? (
    getRightLayout()
  ) : (
    <Container>
      <LeftContainer>
        {getHeader()}
        <MainContent toppadding={calculateTopPadding()}>
          <AnimatedTitle
            showProgressBar={showProgressBar}
            routeName={route}
            cameAfterBrowserBackButtonClick={cameAfterBrowserBackButtonClick}
            cameAfterOshiHeaderBackButtonClick={
              cameAfterOshiHeaderBackButtonClick
            }
            animationRef={titleAnimeRef}
            resetAnimaions={resetAnimaions}
            animationDirection={animationDirection}
            title={title}
            titleStyle={titleStyle}
            preHeader={preHeader}
            titleContainerStyle={titleContainerStyle}
          />

          <AnimatedContent
            routeName={route}
            style={{
              flexDirection: 'column',
              height: '100%',
              ...(isMarpaiRoute ? { opacity: 1 } : {}),
              ...animatedContentStyles
            }}
            cameAfterBrowserBackButtonClick={cameAfterBrowserBackButtonClick}
            animationRef={contentAnimeRef}
            resetAnimaions={resetAnimaions}
            animationDirection={animationDirection}
            handleAnimationFinish={handleAnimationFinish}
          >
            {children}
            {getBottomContainer()}
          </AnimatedContent>
        </MainContent>
      </LeftContainer>
      <BackgroundBanner />
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  overflow-y: hidden;
  height: 100%;
  display: flex;
  flex: 1;
  background: ${palette.background};
  position: relative;
  justify-content: center;
  overflow-x: hidden;
`;

const LeftContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex: 1;
  background: ${palette.background};
  position: relative;
  justify-content: center;
  overflow-x: hidden;
  flex-direction: column;
  align-items: center;

  @media only screen and (min-width: 769px) {
    justify-content: flex-start;
  }
`;

const MainContent = styled.main`
  width: 90%;
  display: flex;
  margin: 0 24px;
  flex-direction: column;
  position: relative;
  max-width: 542px;
  align-items: center;
  height: 100vh;
  height: 100svh;
  
  @media only screen and (min-width: 769px) {
    top: ${({ toppadding }) => `calc(${toppadding}px`});
    height: ${({ toppadding }) => `calc(100vh - ${toppadding}px`});
  }
`;

const BottomContainer = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const LayoutContainer = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex: 1;
  position: relative;
  background: ${palette.background};
`;

const RightContainer = styled.div`
  width: 60%;
  display: flex;
  flex: 1;
  background: ${palette.background};
  overflow-x: hidden;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100%;

  @media only screen and (min-width: 769px) {
    justify-content: center;
  }
}`;

const BannerContainer = styled.div`
  display: flex;
  flex-direction: column;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  color: ${palette.navy500};
  font-size: 14px;
  font-weight: 600;
  font-family: Commissioner;
  line-height: 21px;
  text-align: center;
  padding: 12px 0;
  flex-shrink: 0;
  background: ${palette.melon300};
`;

const FlexContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-start;
  max-width: 100%;       
  box-sizing: border-box;
  flex-wrap: wrap;

  @media only screen and (min-width: 769px) {
    justify-content: center;
    gap: 42px;
    max-width: 600px;
    flex: 1;
  }
`;

const ContentWrapper = styled.main`
  height: auto;
  display: flex;
  flex-direction: column;
  gap: 42px;
  padding: 24px;
`;

const FooterContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: auto;

  @media only screen and (max-height: 800px) {
    min-height: 0;
  }
`;

export default connect(null, { trackSignOutBtnPressed, userBackButtonEvent })(
  AnimatedRoute
);
